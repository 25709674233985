<template>
    <div class="ml-10 mr-10">
        <TitleBar v-if="this.$route.params.uuid === 'new'" :loading="loading">{{ $t("receipt.new") }}</TitleBar>
        <TitleBar v-else
                  :disabled="loading"
                  :loading="loading"
                  :menu="[
                      {text: this.$t('receipt.download'), callback: downloadReceipt},
                      {text: this.$t('receipt.duplicate'), callback: duplicateReceipt},
                      {text: this.$t('receipt.delete'), callback: deleteElement, class: 'red--text'}]"
        >
            {{ $t("receipt.edit") }}
        </TitleBar>

        <Tags v-if="this.$route.params.uuid !== 'new'" :element="$route.params.uuid"></Tags>

        <v-form @submit.prevent="formSubmit">
            <v-row>
                <v-col cols="12" md="4">
                    <v-autocomplete
                        v-model="fromR"
                        :disabled="loading || this.$route.params.uuid !== 'new'"
                        :error-messages="fromRErrors"
                        :items="customers"
                        :label="$t('receipt.from')"
                        :no-data-text="$t('no_data')"
                        item-text="name"
                        item-value="uuid"
                        outlined
                        @blur="$v.fromR.$touch()"
                        @input="$v.fromR.$touch()"
                    ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="4">
                    <v-autocomplete
                        v-model="forR"
                        :disabled="loading || this.$route.params.uuid !== 'new'"
                        :error-messages="forRErrors"
                        :items="customers"
                        :label="$t('receipt.for')"
                        :no-data-text="$t('no_data')"
                        item-text="name"
                        item-value="uuid"
                        outlined
                        @blur="$v.forR.$touch()"
                        @input="$v.forR.$touch()"
                    ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="4">
                    <v-select
                        v-model="currency"
                        :disabled="loading"
                        :error-messages="currencyErrors"
                        :items="currencies"
                        :label="$t('receipt.currency')"
                        outlined
                        @blur="$v.currency.$touch()"
                        @input="$v.currency.$touch()"
                    ></v-select>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="4">
                    <DatePicker
                        v-model="payment_date"
                        :disabled="loading"
                        :error-messages="paymentDateErrors"
                        :label="$t('receipt.payment_date')"
                        @blur="$v.payment_date.$touch()"
                        @input="$v.payment_date.$touch()"
                    ></DatePicker>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        v-model="payment_method"
                        :disabled="loading"
                        :error-messages="paymentMethodErrors"
                        :label="$t('receipt.payment_method')"
                        outlined
                        @blur="$v.payment_method.$touch()"
                        @input="$v.payment_method.$touch()"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-autocomplete
                        v-model="invoice"
                        :disabled="loading"
                        :error-messages="invoiceErrors"
                        :items="invoices"
                        :label="$t('receipt.invoice')"
                        :no-data-text="$t('no_data')"
                        item-text="invoice_number"
                        item-value="uuid"
                        outlined
                        @blur="$v.invoice.$touch()"
                        @input="$v.invoice.$touch()"
                    ></v-autocomplete>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <v-textarea
                        v-model="description"
                        :label="$t('receipt.description')"
                        auto-grow
                        outlined
                        rows="3"
                    ></v-textarea>
                </v-col>
            </v-row>

            <Items
                v-model="items"
                :disabled="loading"
                :label="$t('receipt.items')"
            >
            </Items>

            <v-btn
                :loading="loading"
                color="secondary"
                elevation="2"
                type="submit"
            >{{ $route.params.uuid === "new" ? $t("receipt.add") : $t("receipt.update") }}
            </v-btn>
        </v-form>


        <!--        Fields -->

        <Attachments v-if="this.$route.params.uuid !== 'new'" :element="$route.params.uuid"></Attachments>

    </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Accounting from "../../helpers/Accounting";
import Tags from "../../components/Tags";
import Attachments from "../../components/Attachments";
import Currencies from "../../helpers/Currencies";
import DatePicker from "../../components/DatePicker";
import Items from "../../components/Items";
import Utils from "../../helpers/Utils";
import TitleBar from "@/components/TitleBar.vue";
import DynamicElement from "@/helpers/DynamicElement";

export default {
    name: "Details",
    components: {TitleBar, Items, DatePicker, Attachments, Tags},
    async mounted() {
        this.$store.commit("SET_BREADCRUMBS", [
            {
                text: this.$t("menu.home"),
                to: "/",
                exact: true
            },
            {
                text: this.$t("menu.receipts"),
                to: "/receipts",
                exact: true
            },
            {
                text: this.$route.params.uuid === "new" ? this.$t("receipt.new") : this.$t("receipt.edit"),
                to: "/receipts/" + this.$route.params.uuid,
                exact: true
            }
        ]);

        this.loading = true;
        if (this.$route.params.uuid === "new") {
            let customers = await Accounting.get("/contacts?fields=name,uuid&per_page=-1&page=1").catch(() => {
            });
            this.customers = customers.data.contacts;
        }
        this.customers.push({
            name: this.$store.state.company.name,
            uuid: this.$store.state.company.uuid
        });

        let invoices = await Accounting.get("/incomes/invoices?fields=invoice_number,uuid&per_page=-1&page=1").catch(() => {
        });
        this.invoices = invoices.data.invoices;
        this.loading = false;

        if (this.$route.params.uuid !== "new") {
            this.loading = true;
            Accounting.get("/receipts/" + this.$route.params.uuid)
                .then(async response => {
                    const data = response.data;
                    this.items = data.items;
                    this.fromR = data.from;
                    this.forR = data.for;
                    this.currency = data.currency;
                    this.payment_date = data.payment_date;
                    this.payment_method = data.payment_method;
                    this.invoice = data.invoice;
                    this.description = data.description;
                    this.receipt_number = data.receipt_number;

                    if (this.fromR === this.$store.state.company.uuid) {
                        this.contacts.push({
                            name: await DynamicElement.cache("contact", DynamicElement.getCustomer, this.forR),
                            uuid: this.contact
                        });
                    } else {
                        this.contacts.push({
                            name: await DynamicElement.cache("contact", DynamicElement.getCustomer, this.fromR),
                            uuid: this.contact
                        });
                    }

                    this.loading = false;
                }).catch(error => {
                if (error.response) {
                    let errors = [];
                    for (const errorElement of error.response.data.errors) {
                        errors.push({
                            type: "error",
                            text: errorElement
                        });
                    }
                    this.$store.commit("SET_ALERTS", errors);
                } else if (error.request) {
                    this.$store.commit("SET_ALERTS", [{
                        type: "error",
                        text: this.$t("api.no_response")
                    }]);
                } else {
                    this.$store.commit("SET_ALERTS", [{
                        type: "error",
                        text: error.message
                    }]);
                }
                this.loading = false;
            });
        }
    },
    data: () => {
        return {
            loading: false,
            currencies: Currencies.get(),
            customers: [],
            fromR: null,
            forR: null,
            currency: null,
            items: [],
            payment_date: null,
            payment_method: null,
            invoice: null,
            description: null,
            invoices: [],
            receipt_number: null
        };
    },
    methods: {
        downloadReceipt() {
            this.loading = true;
            Accounting.blob("/receipts/" + this.$route.params.uuid + "/document")
                .then(blob => {
                    let object = URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = object;
                    link.download = this.receipt_number;
                    link.target = "_blank";
                    document.body.appendChild(link);
                    link.dispatchEvent(
                        new MouseEvent("click", {
                            bubbles: true,
                            cancelable: true,
                            view: window
                        })
                    );
                    document.body.removeChild(link);
                    this.loading = false;
                }).catch(() => {
                this.loading = false;
            });
        },
        deleteElement() {
            this.$swal({
                title: this.$t("receipt.delete_title"),
                text: this.$t("receipt.delete_text"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: this.$t("receipt.delete_yes"),
                cancelButtonText: this.$t("receipt.delete_no"),
                confirmButtonColor: Utils.getColor(this, "error"),
                cancelButtonColor: Utils.getColor(this, "primary")
            }).then(result => {
                if (result.isConfirmed) {
                    this.loading = true;
                    this.$store.commit("SET_ALERTS", []);
                    Accounting.delete("/receipts/" + this.$route.params.uuid)
                        .then(() => {
                            this.$store.commit("SET_ALERTS", [{
                                type: "success",
                                text: this.$t("receipt.deleted")
                            }]);
                            this.loading = false;
                            this.$router.push("/receipts");
                        })
                        .catch(error => {
                            if (error.response) {
                                let errors = [];
                                for (const errorElement of error.response.data.errors) {
                                    errors.push({
                                        type: "error",
                                        text: errorElement
                                    });
                                }
                                this.$store.commit("SET_ALERTS", errors);
                            } else if (error.request) {
                                this.$store.commit("SET_ALERTS", [{
                                    type: "error",
                                    text: this.$t("api.no_response")
                                }]);
                            } else {
                                this.$store.commit("SET_ALERTS", [{
                                    type: "error",
                                    text: error.message
                                }]);
                            }
                            this.loading = false;
                        });
                }
            });
        },
        duplicateReceipt() {
            this.$router.push({
                name: "receipts.details",
                params: {uuid: "new"}
            });
        },
        formSubmit() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.loading = true;

                let url = "/receipts";

                if (this.$route.params.uuid !== "new") {
                    url = url + "/" + this.$route.params.uuid;
                }

                this.$store.commit("SET_ALERTS", []);

                Accounting.post(url, {
                    from: this.fromR,
                    for: this.forR,
                    currency: this.currency,
                    payment_date: this.payment_date,
                    payment_method: this.payment_method,
                    invoice: this.invoice,
                    description: this.description,
                    items: this.items
                }).then(response => {

                    this.loading = false;
                    if (this.$route.params.uuid === "new") {
                        this.$store.commit("SET_ALERTS", [{
                            type: "success",
                            text: this.$t("receipt.added")
                        }]);
                        this.$router.push("/receipts/" + response.data.uuid);
                    } else {
                        this.$store.commit("SET_ALERTS", [{
                            type: "success",
                            text: this.$t("receipt.updated")
                        }]);
                    }

                }).catch(error => {
                    if (error.response) {
                        let errors = [];
                        for (const errorElement of error.response.data.errors) {
                            errors.push({
                                type: "error",
                                text: errorElement
                            });
                        }
                        this.$store.commit("SET_ALERTS", errors);
                    } else if (error.request) {
                        this.$store.commit("SET_ALERTS", [{
                            type: "error",
                            text: this.$t("api.no_response")
                        }]);
                    } else {
                        this.$store.commit("SET_ALERTS", [{
                            type: "error",
                            text: error.message
                        }]);
                    }
                    this.loading = false;
                });

            }
        }
    },
    mixins: [validationMixin],
    validations: {
        fromR: {required},
        forR: {required},
        currency: {required},
        payment_date: {required},
        payment_method: {required},
        invoice: {required}
    },
    computed: {
        fromRErrors() {
            const errors = [];
            if (!this.$v.fromR.$dirty) return errors;
            !this.$v.fromR.required && errors.push(this.$t("receipt.from_required"));
            return errors;
        },
        forRErrors() {
            const errors = [];
            if (!this.$v.forR.$dirty) return errors;
            !this.$v.forR.required && errors.push(this.$t("receipt.for_required"));
            return errors;
        },
        currencyErrors() {
            const errors = [];
            if (!this.$v.currency.$dirty) return errors;
            !this.$v.currency.required && errors.push(this.$t("receipt.currency_required"));
            return errors;
        },
        paymentDateErrors() {
            const errors = [];
            if (!this.$v.payment_date.$dirty) return errors;
            !this.$v.payment_date.required && errors.push(this.$t("receipt.payment_date_required"));
            return errors;
        },
        paymentMethodErrors() {
            const errors = [];
            if (!this.$v.payment_method.$dirty) return errors;
            !this.$v.payment_method.required && errors.push(this.$t("receipt.payment_method_required"));
            return errors;
        },
        invoiceErrors() {
            const errors = [];
            if (!this.$v.invoice.$dirty) return errors;
            !this.$v.invoice.required && errors.push(this.$t("receipt.invoice_required"));
            return errors;
        }
    }
};
</script>

<style scoped>

</style>